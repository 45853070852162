<template>
  <div class="guest-auth">
    <h1>{{ $t('guest_auth.title') }}</h1>
    <p class="guest-info-block">
      {{ authenticationText || $t('guest_auth.information_block') }}
    </p>

    <ProfileDetail anonymize />
    <LanguageSelect absolutePosition />

    <div>
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="onSubmit" class="guest-auth-form">
          <div class="reservation-number">
            <ValidationProvider name="query" rules="required|min:2" v-slot="{ errors }">
              <Input v-model="query"
                name="query"
                disabled
                :error="alreadySent ? errors[0] : null"
                :placeholder="$t('guest_auth.form.query.placeholder')"
              />
            </ValidationProvider>
          </div>

          <div class="keyboard-block">
            <SimpleKeyboard @onChange="onChange" :input="query"/>
          </div>

          <ButtonInput type="submit" :disabled="invalid || formSending" :loading="formSending">
            {{ $t('guest_auth.form.submit_button') }}
          </ButtonInput>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Input from '@/components/Form/Input/Input';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import { mapGetters } from 'vuex';
import LanguageSelect from '@/components/LanguageSelect/index';
import ProfileDetail from '@/components/ProfileDetail';
import { GET_RESERVATION_DETAIL } from '@/store/reservation';
import SimpleKeyboard from '@/components/SimpleKeyboard';
import flashMessage from '@/helpers/flashMessage';
import * as endpoints from '@/api/handler.endpoints';
import * as methods from '@/api/handler.methods';
import api from '@/api/handler';
import {
  RESERVATION_ACTION_CHECKIN,
  RESERVATION_ACTION_LOST_CARD,
} from '@/constants/action.type';
import { GET_PROPERTY_DETAIL } from '@/store/property';
import { FALLBACK_LANGUAGE, ISO_CODE_TO_LANGUAGE } from '@/constants/trans';
// import errorHandler from '@/api/errorHandler';
import { logEvent } from '@/helpers/analytics';

export default {
  name: 'GuestAuthentication',
  components: {
    Input,
    ButtonInput,
    LanguageSelect,
    ProfileDetail,
    SimpleKeyboard,
  },
  async mounted() {
    if (!this.reservation) {
      await this.$router.push('/home');
    }
  },
  data() {
    return {
      query: null,
      type: this.$route.params.type,
      alreadySent: false,
      formSending: false,
    };
  },
  computed: {
    ...mapGetters({
      property: GET_PROPERTY_DETAIL,
      reservation: GET_RESERVATION_DETAIL,
    }),
    authenticationText() {
      const fallback = _.get(this.property, `settings.guestAuthentication.${ISO_CODE_TO_LANGUAGE[FALLBACK_LANGUAGE]}`);
      return _.get(this.property, `settings.guestAuthentication.${ISO_CODE_TO_LANGUAGE[this.$i18n.locale] || this.$i18n.locale}`) || fallback;
    },
  },
  methods: {
    onChange(query) {
      this.query = query;
    },
    onSubmit () {
      this.alreadySent = true;
      this.authenticateGuest();
    },
    authenticateGuest() {
      this.formSending = true;
      api(this, endpoints.ENDPOINT_GUEST_AUTHORIZE, methods.POST_GUEST_AUTHORIZE)(this.reservation.id, this.query)
        .then(() => {
          logEvent('onGuestAuthenticationAuthenticateClicked');

          switch (this.type) {
          case RESERVATION_ACTION_CHECKIN:
            this.$router.push('/issue-card');
            break;
          case RESERVATION_ACTION_LOST_CARD:
            this.$router.push('/issue-card/lost-card');
            break;
          }
        })
        .catch((err) => {
          this.formSending = false;
          const responseCode = parseInt(_.get(err, 'response.data.responseCode'), 10);
          if (responseCode === 401) {
            flashMessage(this.$store, this.$t('guest_auth.alert.error.auth_failed'), 'danger');
          } else {
            flashMessage(this.$store, this.$t('guest_auth.alert.error.send_error'), 'danger');
          }
        });
    },
  },
};
</script>

<style lang="scss">
  .guest-auth {
    width: calc(100% - 4rem);
    max-width: 50rem;
    text-align: center;

    .guest-info-block {
      max-width: 30rem;
      padding: 0 2rem;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .keyboard-block {
    margin-bottom: 1rem;
  }

  .reservation-number {
    .form-input {
      border: 0;
      background: none;
      text-align: center;
      font-size: 1.2rem;
    }
  }
</style>
